/////////////////////////////////////////////////////////////////////////////
// contract expiration notices and denial of service because of missing
// contract
/////////////////////////////////////////////////////////////////////////////

window.show_contract_expiration_notices = function show_contract_expiration_notices(controller, action) {
  // contract expiration notices on schules/index
  if (controller == "schules" && action == "index"){
    $.ajax({
      type: "GET",
      url: '/schule_products/contract_expiration_notice',
      dataType: "script"
    });
  }

  // user wants to enter schule without current contract allowing entry
  if (["schules", "ots_schules"].includes(controller)){
    $('.no-current-entry-contract').click(function(e){
      elt = e.target.closest('a');
      var path = elt.getAttribute("data-path");

      $.ajax({
        type: "GET",
        url: path,
        dataType: "script"
      });
    });
  }
}
